import React, { Component } from 'react';
import TimeEntry from "./TimeEntry";

import './timeEntryList.css'

export default class TimeEntryList extends Component {
	render() {
		var timeEntries = this.props.timeEntries.map((timeEntry) =>
			<TimeEntry timeEntry={timeEntry} key={timeEntry.id}/>);
		return (
			<div>
				<div className="timeentry-header-desktop">
					<p>Date</p>
					<p>Start</p>
					<p>End</p>
					<p>Duration</p>
					<p>Location</p>
				</div>

				<div className="timeentry-header-mobile">
					<p>Date</p>
					<p>Time</p>
					<p>Duration</p>
					<p>Location</p>
				</div>
				<ul className="timeentry-list">
					{timeEntries}
				</ul>
			</div>
		);
	}
}