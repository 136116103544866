import React, { Component } from 'react';
import './timeEntry.css';

export default class TimeEntry extends Component {
	render() {
		const timeEntry = this.props.timeEntry;
		return (
			<li>
				<div className="timeentry-card">
					<div className="timeentry-row-desktop">
						<p>{new Date(timeEntry.start).toLocaleDateString()}</p>
						<p>{new Date(timeEntry.start).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</p>
						<p>{new Date(timeEntry.end).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</p>
						<p>{timeEntry.duration} Minutes</p>
						<p>{this.toLocationString(timeEntry.location)}</p>
					</div>
					<div className="timeentry-row-mobile">
						<p>{this.formatDate(timeEntry.start, true, true, false)}</p>
						<p>{`${new Date(timeEntry.start).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}-${new Date(timeEntry.end).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`}</p>
						<p>{timeEntry.duration} min.</p>
						<p>{this.toLocationString(timeEntry.location)}</p>
					</div>
				</div>
			</li>
		);
	}

	formatDate(date, includeDay, includeMonth, includeYear) {
		var d = new Date(date);
		var month = (d.getMonth() + 1);
		var day = d.getDate();
		var year = d.getFullYear();

		var fields = [];

		if (includeMonth === true) {
			fields.push(month);
		}
		if (includeDay === true) {
			fields.push(day);
		}
		if (includeYear === true) {
			fields.push(year);
        }

		return fields.join('/');
	}

	toLocationString(location) {
		var map = {
			"0": "Home",
			"1": "School",
			"2": "Office",
			"3": "Telecare"
		};
		return map[location];
	}
}