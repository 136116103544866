import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import ProviderInvoiceList from './components/Invoice/providerInvoiceList';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { getAppInsights } from './applicationInsights/TelemetryService';
import TelemetryProvider from './applicationInsights/telemetry-provider';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import authService from './components/api-authorization/AuthorizeService';

import './custom.css'

const App = () => {
    let appInsights = null;
    function trackException(error) {
        appInsights.trackException(error);
    }
    function trackTrace(message) {
        appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
    }
    function trackEvent(event) {
        appInsights.trackEvent(event);
    }
    async function appInsightsSetUp() {
        appInsights = getAppInsights();

        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            const user = await authService.getUser();
            appInsights.setAuthenticatedUserContext(user.name);
        }
    }
    return (
        <TelemetryProvider
            instrumentationKey="08ce5128-63ad-4976-92bb-381baacecaed"
            after={appInsightsSetUp}>
            <Layout>
                <AuthorizeRoute exact path='/' component={ProviderInvoiceList} />
                <AuthorizeRoute path='/invoice' component={ProviderInvoiceList} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        </TelemetryProvider>
    );
};
export default App;
