import authService from './components/api-authorization/AuthorizeService';

export async function getInvoices() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/invoice', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    var invoices = await response.json();
    return invoices;
}

export async function signInvoices(invoices, signature, name) {
    const token = await authService.getAccessToken();
    await fetch('api/invoice/sign', {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ invoices: invoices, signature: signature, name: name })
    });
}