import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import './signature.css';

export default class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = { name: "" };
        this.handleChange = this.handleChange.bind(this);
        this.signInvoices = this.signInvoices.bind(this);
    }
    render() {
        let error = null;
        if (this.state.error) {
            error = <p align="center" className="error-text"> {this.state.error} </p>
        }
        return (
			<div>
				<div className="sigCanvas" >
					<SignaturePad ref={(ref) => { this.mySignature = ref }} redrawOnResize={true} height={175} />
				</div>
				<button className="clear-btn-desktop" onClick={() => this.mySignature.clear()}>Clear signature</button>
                {error}
                <div className="signature-footer">
					<input className="name" type="text" name="name" onChange={this.handleChange} placeholder="Full name" />
					<button className="clear-btn-mobile" onClick={() => this.mySignature.clear()}>Clear</button>
                    <button className="btn-apply" onClick={() => this.signInvoices(this.props.onSign)}>
                        SUBMIT
                    </button >
                </div>
            </div>
        );
    }
    handleChange(event) {
        this.setState({ name: event.target.value });
    }
    //not behaving as expected 
    //isValidSignature(signature) {
    //    const points = signature.points;
    //    let minX = 0, maxX = 0, minY = 0, maxY = 0;
    //    points.forEach(point => {
    //        minX = point.x < minX ? point.x : minX;
    //        minY = point.y < minY ? point.y : minY;
    //        maxX = point.x > maxX ? point.x : maxX;
    //        maxY = point.y > maxY ? point.y : maxY;
    //    });
    //    const xDiff = Math.abs(minX - maxX);
    //    const yDiff = Math.abs(minY - maxY);
    //    const canvas = signature.refs.cv;
    //    if (xDiff < canvas.width / 2 || yDiff < canvas.height / 2) {
    //        return false;
    //    }
    //    return true;
    //}
    signInvoices(callback) {
        var signature = this.mySignature;
        if (signature.isEmpty()) {
            this.setState({ error: "Signature is empty" });
            return;
        }
        var name = this.state.name;
        if (!name) {
            this.setState({ error: "Name is empty" });
            return;
        }
        callback(signature.toDataURL(), name);

    }
}