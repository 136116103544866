
import React, { Component } from 'react';
import ProviderInvoice from "./ProviderInvoice";
import Signature from './signature';
import { getInvoices, signInvoices } from '../../ApiService';
import { Modal } from 'react-responsive-modal';
import { MdCheck, MdClose } from "react-icons/md";
import Loader from 'react-loader-spinner'
import './ProviderInvoiceList.css';
import 'react-responsive-modal/styles.css';

export default class ProviderInvoiceList extends Component {
    static displayName = "INVOICES";
    constructor(props) {
        super(props);
        this.state = { invoices: null, loading: true, error: null, showSubmitLoader: false };
        this.selectInvoice = this.selectInvoice.bind(this);
        this.showSignatureModal = this.showSignatureModal.bind(this);
        this.closeSignatureModal = this.closeSignatureModal.bind(this);
        this.showSubmitLoader = this.showSubmitLoader.bind(this);
        this.hideSubmitLoader = this.hideSubmitLoader.bind(this);
        this.signInvoices = this.signInvoices.bind(this);
        this.getSelectedInvoices = this.getSelectedInvoices.bind(this);
        this.sign = this.sign.bind(this);
    }

    componentDidMount() {
        this.getInvoices();
    }

    render() {
        let content = null
        const invoices = this.state.invoices;
        if (this.state.loading) {
            content =
                <div className="loader-container">
                    <div className="loader-wrapper">
                        <Loader className="loader" type="Oval" color="#f9a54a" height={60} width={60} />
                        <div>Loading invoices...</div>
                    </div>
                </div>
        }
        if (this.state.error) {
            return (
                <p> {this.state.error} </p>
            );
        }
        if (invoices) {
            content = invoices.length && invoices.length > 0 ?
                <ul className="invoice-list">
                    <label className="checkbox-container ml-3">
                        <input type="checkbox"
                            className="invoice-checkbox"
                            checked={this.getSelectedInvoices().length === invoices.length}
                            onChange={(e) => this.toggleSelectAllInvoices(e)} />
                        <span className="checkmark mt-1"></span>
                        <span className="invoice-header-value">Select All</span>
                    </label>
                    {invoices.map((invoice) =>
                        <li className="invoice-box" key={invoice.id}>
                            <label className="checkbox-container">
                                <input type="checkbox"
                                    className="invoice-checkbox"
                                    checked={this.getSelectedInvoices().includes(invoice)}
                                    onChange={(e) => this.selectInvoice(e, invoice.id)} />
                                <span className="checkmark"></span>
                            </label>
                            <ProviderInvoice invoice={invoice} />
                        </li>)
                    }
                </ul>
                :
                <div className="no-invoices-cotainer">
                    <div className="no-invoices">
                        <p>No pending invoices</p>
                    </div>
                </div>
        }
        return (
            <div className="invoice-list-container">
                <p className="invoice-list-header">Your signature is required. Please review and select approved invoices.</p>
                <div className="invoice-container">
                    {content}
                    {this.state.showSubmitLoader &&
                        <div className="loader-container submit-loader">
                            <div className="loader-wrapper">
                                <Loader className="loader" type="Oval" color="#f9a54a" height={60} width={60} />
                                <div>Submitting invoices...</div>
                            </div>
                        </div>}
                </div>
                {(this.state.invoices && this.state.invoices.length > 0) &&
                    <button className="btn-sign" onClick={this.showSignatureModal}>
                        SIGN
                    </button>}
                <Modal
                    center
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    open={this.state.showSignatureModal}>
                    <div className="rotate-instructions">
                        <p>Please rotate device to sign</p>
                    </div>
                    <div className="sign-content">
                        <div className="sign-modal-header">
                            <button className="modal-close" onClick={this.closeSignatureModal}><MdClose className="close-icon" /></button>
                            <p className="sign-header">Draw your signature</p>
                            <button className="modal-apply" onClick={this.sign}><MdCheck className="check-icon" /></button>
                        </div>
                        <p className="signature-confirm-text">By my signature, I acknowledge that I have reviewed this billing form and that, to the best of
                            my knowledge, these sessions were provided as indicated.</p>
                        <Signature className="signature" onSign={this.signInvoices} ref={(c) => this.signature = c} />
                    </div>
                </Modal>
            </div>
        );
    }

    selectInvoice(e, selectId) {
        let selectedInvoices = this.state.selectedInvoices || [];
        if (e.target.checked) {
            selectedInvoices.push(selectId);
        }
        else {
            let index = selectedInvoices.indexOf(selectId);
            if (index > -1) {
                selectedInvoices.splice(index, 1);
            }
        }
        this.setState({ selectedInvoices: selectedInvoices });
    }

    toggleSelectAllInvoices(e) {
        const invoiceIds = this.state.invoices.map(i => i.id) || [];
        if (e.target.checked) {
            this.setState({ selectedInvoices: invoiceIds });
        }
        else {
            this.setState({ selectedInvoices: [] });
        }
    }

    showSignatureModal() {
        if (this.state.selectedInvoices == null) {
            return alert("Please select at least one invoice to sign.");
        };

        this.setState({ showSignatureModal: true });
    }

    closeSignatureModal() {
        this.setState({ showSignatureModal: false });
    }

    showSubmitLoader() {
        this.setState({ showSubmitLoader: true });
    }

    hideSubmitLoader() {
        this.setState({ showSubmitLoader: false });
    }

    sign() {
        this.signature.signInvoices(this.signInvoices)
    }

    getSelectedInvoices() {
        if (!this.state.invoices) {
            return [];
        }
        const selectedIds = this.state.selectedInvoices || [];
        const selectedInvoices = this.state.invoices
            .filter(invoice => selectedIds.includes(invoice.id));
            console.log(selectedInvoices, this.state.invoices);
        return selectedInvoices;
    }

    async getInvoices() {
        this.setState({ loading: true });
        try {
            const invoices = await getInvoices();
            this.setState({ invoices: invoices, loading: false });
        } catch (e) {
            alert("Error fetching invoices. Please click OK to try again.")
            this.setState({ loading: false });
            window.location.reload();
        }
    }

    async signInvoices(signature, name) {
        const invoices = this.getSelectedInvoices();
        const map = invoices.map(invoice => { return { id: invoice.id, type: invoice.signerType } });
        await this.showSubmitLoader();
        await signInvoices(map, signature, name)
        this.closeSignatureModal();
        await this.getInvoices();
        this.hideSubmitLoader();
        alert("Invoice Submitted.");
    }
}