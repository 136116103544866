import React, { Component } from 'react';
import TimeEntryList from "../TimeEntry/timeEntryList";
import { Collapse, Button } from 'reactstrap';
import { IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import './ProviderInvoice.css'

const ProviderInvoice = (props) => {
    var invoice = props.invoice;
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);

    var monthsOfYear = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

        return (
            <div>
                <div className="invoice-header-desktop">
                    <div className="invoice-header">
                        <div>
                            <p className="invoice-header-key">Patient</p>
                            <p className="invoice-header-value">{invoice.project.name}</p>
                        </div>
                        <div>
                            <p className="invoice-header-key">Provider</p>
                            <p className="invoice-header-value">{invoice.provider.name}</p>
                        </div>
                        <div>
                            <p className="invoice-header-key">Month</p>
                            <p className="invoice-header-value">{monthsOfYear[invoice.month - 1]}, {invoice.year}</p>
                        </div>
                        <div>
                            <p className="invoice-header-key">Hours Invoiced</p>
                            <p className="invoice-header-value">{invoice.totalHours} hours</p>
                        </div>
                        <div>
                        </div>
                    </div>

                    <hr className="header-separator" />
                    <TimeEntryList timeEntries={invoice.timeEntries} />
                </div>
                

                <div className="invoice-header-mobile">
                    <div>
                        <p className="project">{invoice.project.name}</p>
                        <p className="mobile-header-field">Provider: {invoice.provider.name}</p>
                    </div>
                    <div>
                        <p className="mobile-header-field text-right">{invoice.totalHours} hrs</p>
                        <p className="mobile-header-field text-right">{monthsOfYear[invoice.month - 1]}, {invoice.year}</p>
                    </div>
                    <button className="icon-button" onClick={toggle}>

                        {isOpen === true ? <IoIosArrowUp className="toggle-icon" /> : <IoIosArrowDown className="toggle-icon"  />}
                    </button>
                </div>
                <Collapse className="mobile-timeentry-list" isOpen={isOpen}>
                    <hr className="hr-no-style" />
                    <TimeEntryList timeEntries={invoice.timeEntries} />
                </Collapse>
            </div>
		);
}

export default ProviderInvoice;